<style lang="scss">
#client_location {
  .symbol-label {
    height: 40px !important;
    width: 70px !important;
  }
}
</style>
<template>
  <div class="row card" id="client_location">
    <div class="col-md-12 card-body">
      <div class="row">
            <div class="col-md-3 pr-0">
              <div class="form-group">
                <input
                type="text"
                placeholder="Search By Name"
                class="form-control"
                v-model="searchInput"
              />
              </div>
            </div>

            <div class="col-md-1 pl-2 pr-0">
              <div class="form-group">
                <select v-model="status" class="form-control">
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                  <option value="all">All</option>
                  <!-- <option value="Refund">Refunded</option> -->
                </select>
              </div>
            </div>

            <div class="col-md-1">
              <button class="btn btn-outline-info"  @click="search()">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <div class="col-md-1" v-if="true"></div>

            <div class="col-md-6 float-right px-5">
              <router-link
                :to="{ name: 'client.location.create' }"
                v-if="currentUser.u.roles == 'admin'"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Location
                </div>
              </router-link>
            </div>
          </div>
      <table class="table table-striped" style="background: white">
      
        <thead>
          <!-- <tr>
            <th colspan="3">
              <input
                type="text"
                placeholder="Search By Name"
                class="form-control"
                v-model="searchInput"
              />
            </th>

            <th></th>
            <th colspan="4">
              <router-link
                :to="{ name: 'client.location.create' }"
                v-if="currentUser.u.roles == 'admin'"
              >
                <div class="btn btn-info" style="float: right">
                  <i class="fa fa-plus"></i> Add Location
                </div>
              </router-link>
            </th>
          </tr> -->
          <tr>
            <th>SN</th>
            <th>Image</th>
            <th>Link</th>
            <th>Name</th>
            <th>Address</th>
            <th>Time Zone</th>
            <th class="text-center">Status</th>
            <th style="width:19%" v-if="currentUser.u.roles == 'admin'">
              Action
            </th>
          </tr>
        </thead>
        <tbody
          v-if="
            !loadingData && filteredLocations && filteredLocations.length > 0
          "
        >
          <tr v-for="(item, index) in filteredLocations" :key="index">
            <td>{{ index + 1 }}</td>
            <td>
              <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
                <div
                  class="symbol-label"
                  :style="
                    'background-size: contain;background-image: url(' +
                      getBLocations.url +
                      item.image +
                      ')'
                  "
                ></div>
              </div>
            </td>
            <td>
              <a
                :href="getBLocations.f_url + '/client/login?s=' + item.slug"
                target="_blank"
                >{{ item.slug }}</a
              >
            </td>
            <td>{{ item.name }}</td>
            <td>{{ item.full_address }}</td>
            <td>{{ item.client_timezone }}</td>
            <td class="text-center">
              <i v-if="!item.is_active" class="fa fa-times text-danger"></i>
              <i v-if="item.is_active" class="fa fa-check text-success"></i>
            </td>
            <td v-if="currentUser.u.roles == 'admin'" style="width:120px">
              <!-- <b-dropdown
                id="dropdown-1"
                text="Action"
                class="m-md-2"
                variant="primary"
              >
                <b-dropdown-item @click="editRate(item.id)"
                  >Edit Rate</b-dropdown-item
                >
                <b-dropdown-item @click="editItem(item.id)"
                  >Edit Location</b-dropdown-item
                >
                <b-dropdown-item @click="openLocationUsers(item.id)"
                  >Location Users</b-dropdown-item
                >
                <b-dropdown-item @click="openbaseplan(item.id, item.name)"
                  >Base plan</b-dropdown-item
                >
              </b-dropdown> -->
              <button
                class="btn btn-primary mr-3 mt-2 btn-sm"
                @click="editItem(item.id)"
              >
                Edit
              </button>

              <button
                class="btn btn-info mr-3 mt-2 btn-sm"
                @click="openLocationUsers(item.id)"
              >
                Users
              </button>
              <button
                class="btn btn-success mr-3 mt-2 btn-sm"
                @click="editRate(item.id)"
              >
                Rate
              </button>

              <!-- <button>
                <i
                  class="btn btn-success fa fa-eye text-success mr-3"
                  style="cursor: pointer"
                ></i
              ></button> -->

              <button
                class="btn btn-warning mr-3 mt-2 btn-sm"
                @click="openbaseplan(item.id, item.name)"
              >
                Base plan
              </button>
              <button
                @click="deleteItem(item.id,index)"
                class="btn btn-sm btn-danger mr-3 mt-2"
                style="cursor: pointer;"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
        <tbody
          v-if="
            !loadingData && filteredLocations && filteredLocations.length < 1
          "
        >
          <tr>
            <td colspan="8" class="text-center">No record(s) found</td>
          </tr>
        </tbody>
        <tbody v-if="loadingData">
          <tr>
            <th class="text-center" colspan="10">
              <vDataLoader></vDataLoader>
            </th>
          </tr>
        </tbody>
      </table>
    </div>
    
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
import {
  FETCH_B_LOCATIONS,
  DELETE_B_LOCATION
} from "@/core/services/store/actions.type";
import vDataLoader from "@/components/frontend/spinner.vue";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      status: "active",
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Locations",
          route: ""
        }
      ],
      loadingData: false,
      searchInput: ""
    };
  },
  mounted(){
    this.status='active';
  },
  components: {
    vDataLoader
  },
  computed: {
    ...mapGetters(["getBLocations", "currentUser"]),
    filteredLocations() {
      let tempLocation = this.getBLocations.data;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempLocation = tempLocation.filter(item => {
          // console.log(item.name);
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempLocation;
    }
  },
  created() {
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.getLocationData();
  },
  methods: {
    search() {
      this.getLocationData();
    },
    deleteItem(id, index) {
      this.$swal({
        title: "Are you sure?",
        text:
          "Once deleted, you will not be able to recover this location data!",

        confirmButtonText: "Delete",
        cancelButtonText: "Cancel",
        showCancelButton: true,
        showCloseButton: true,
        everseButtons: true
      }).then(willDelete => {
        if (willDelete.isConfirmed) {
          this.$store.dispatch(DELETE_B_LOCATION, id).then(data => {
            this.$toastr.s(data.msg);
            this.getBLocations.data.splice(index, 1);
          });
        }
      });
    },
    editRate(id) {
      this.$router.push({ name: "client.location.rate", params: { id: id } });
    },
    openLocationUsers(id) {
      this.$router.push({ name: "client.location.users", params: { id: id } });
    },
    editItem(id) {
      this.$router.push({ name: "client.location.edit", params: { id: id } });
    },
    openbaseplan(id, name) {
      let client_slug = this.$route.params.client_slug;
      let client = this.currentUser.u.is_client ? "c" : "sp";
      this.$router.push({
        name: "client.baseplan.location",
        params: {
          location_id: id,
          client_slug: client_slug,
          client: client,
          location: name
        }
      });
    },
    getLocationData() {
      this.loadingData = true;
      this.$store
        .dispatch(FETCH_B_LOCATIONS,this.status)
        .then(() => {
          this.loadingData = false;
        })
        .catch(() => {
          this.loadingData = false;
        });
    }
  }
};
</script>

<style></style>
